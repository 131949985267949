import { useState, useEffect } from "react";

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState("");
  let timeLeftHeader = (
    <>
      <p>ZAPISZ SIĘ I ZGARNIJ RABAT, KTÓRY WYKORZYSTASZ NA 👇👇</p>
      <a href="https://shop.polonarko.com/" style={{ color: "black" }}>
        SHOP.POLONARKO.COM
      </a>
    </>
  );

  useEffect(() => {
    const countdownTime = new Date("Oct 31, 2024 23:59:59").getTime();

    const updateTimer = () => {
      const currentTime = new Date().getTime();
      const distance = countdownTime - currentTime;

      const totalHours = Math.floor(distance / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft(
        `${totalHours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    };

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, []);

  if (timeLeft.startsWith("-")) {
    console.log("Jestem na minusie");
  }

  return (
    <div className="timer d-flex" role="timer">
      {timeLeft.startsWith("-") ? (
        <div className="timeLeftContainer">{timeLeftHeader}</div>
      ) : (
        <p id="hours">{timeLeft}</p>
      )}
      {/* <p id="hours">{timeLeft}</p> */}
    </div>
  );
};

export default Timer;
